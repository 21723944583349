<template>
  <div class="custom-tree">
    <a-table
      :columns="columns"
      :data-source="treeData"
      :pagination="false"
      :bordered="false"
      row-key="categoryId"
      :expanded-row-keys="expandedRowKeys"
      :customRow="customRow"
    >
      <template slot="categoryName" slot-scope="text, record">
        {{ text }}
        <!-- 加载loading -->
        <a-spin v-if="record.loading" class="ml10" />
      </template>
      <template slot="action" slot-scope="text, record">
        <a class="mr10" @click.stop="addCategory(record)">{{ $t('新增类目') }}</a>
        <a-divider type="vertical" />
        <a class="mr10" @click.stop="editCategory(record)">{{ $t('修改类目') }}</a>
        <a-divider type="vertical" />
        <a class="mr10 text-danger" @click.stop="deleteCategory(record)">{{ $t('删除类目') }}</a>
        <a-divider type="vertical" v-if="record.leaf" />
        <a v-if="record.leaf" @click.stop="editSpec(record)">{{ $t('编辑规格') }}</a>
      </template>
    </a-table>

    <a-modal
      width="500px"
      v-model="specsVisible"
      :title="$t('编辑规格')"
      :confirm-loading="modalLoading"
      @ok="saveSpecs"
    >
      <div class="flex-ct gap-10 mb10">
        <div class="label">{{ $t('规格名称') }}:</div>
        <CommonSelect
          style="width: 300px"
          :list="commonStyleTypeList"
          labelName="styleTypeName"
          valueName="styleType"
          mode="multiple"
          :code.sync="specsData.addSpecs"
        />
        <a-button type="primary" @click="addSpecHandle">{{ $t('添加规格') }}</a-button>
      </div>
      <a-list :dataSource="specsData.styleTypes">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <span>{{ item.styleTypeName }}</span>
          <a-button type="link" @click="removeSpec(index)">{{ $t('删除') }}</a-button>
        </a-list-item>
      </a-list>
    </a-modal>
  </div>
</template>

<script>
import http from '@service/axios'
import api from '@service/api'
import { Modal } from 'ant-design-vue'
import CommonSelect from '@/component/commonSelect'
import deepClone from '@/common/deepClone'
export default {
  name: 'CustomTree',
  components: { CommonSelect },
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      expandedRowKeys: [],
      specsVisible: false,
      specsData: {
        indexLevel: undefined,
        categoryId: undefined,
        addSpecs: [], //新增的规格 ['aa','bb']
        styleTypes: [], //已有的规格 [{styleType: 'cc', styleTypeName: 'cc'}]
      },
      modalLoading: false,
      commonStyleTypeList: [], //规格列表
    }
  },
  computed: {
    columns() {
      return [
        {
          title: this.$t('类目名称'),
          dataIndex: 'categoryName',
          scopedSlots: { customRender: 'categoryName' },
        },
        {
          title: this.$t('类目ID'),
          dataIndex: 'categoryId',
        },
        {
          title: this.$t('规格'),
          dataIndex: 'spec',
          customRender: (text, record) => {
            let str = ''
            if (!record.leaf) {
              return str
            }
            if (record.styleTypeList?.length > 0) {
              str += record.styleTypeList.map((item) => item.styleTypeName).join('/')
            }
            return str
          },
        },
        {
          title: this.$t('操作'),
          scopedSlots: { customRender: 'action' },
        },
      ]
    },
  },
  methods: {
    toggleExpand(record) {
      const expanded = this.expandedRowKeys.includes(record.categoryId)
      if (expanded) {
        this.expandedRowKeys = this.expandedRowKeys.filter((key) => key !== record.categoryId)
      } else {
        this.expandedRowKeys = [...this.expandedRowKeys, record.categoryId]
      }
      this.handleExpand(!expanded, record)
    },
    customRow(record) {
      return {
        on: {
          click: (event) => {
            // 判断点击是否来自操作列
            const isActionColumn = event.target.closest('td:last-child')
            if (!isActionColumn) {
              if ((record.children && record.children.length > 0) || !record.leaf) {
                this.toggleExpand(record)
              }
            }
          },
        },
      }
    },
    handleExpand(expanded, record) {
      if (!record.children?.length && !record.leaf) {
        this.$emit('loadData', record.indexLevel)
      }
      this.$set(record, 'expanded', expanded)
    },
    addCategory(node) {
      this.$emit('addCategory', node)
    },
    editCategory(node) {
      this.$emit('editCategory', node)
    },
    deleteCategory(data) {
      Modal.confirm({
        title: this.$t('删除类目'),
        content: this.$t('确定删除X类目吗？', { X: data.categoryName }),
        onOk: async () => {
          const res = await http({
            type: 'post',
            url: api.deleteCategory,
            data: {
              categoryId: data.categoryId,
            },
          })
          if (res.success) {
            this.$success(this.$t(`删除成功`))
            this.$emit('getList', data.indexLevel)
          }
        },
      })
    },
    editSpec(dataRef) {
      this.specsData.indexLevel = dataRef.indexLevel
      this.specsData.categoryId = dataRef.categoryId
      this.specsData.styleTypes = deepClone(dataRef.styleTypeList) || []
      this.specsVisible = true
      this.queryStyleTypeList()
    },
    addSpecHandle() {
      const addSpecs = this.specsData.addSpecs.map((item) => ({
        styleType: item,
        styleTypeName: this.commonStyleTypeList.find((i) => i.styleType === item).styleTypeName,
      }))
      //specsData.addSpecs和specsData.styleTypes 合并并去重
      this.specsData.styleTypes = [...this.specsData.styleTypes, ...addSpecs].reduce((acc, cur) => {
        if (!acc.find((item) => item.styleType === cur.styleType)) {
          acc.push(cur)
        }
        return acc
      }, [])
      this.specsData.addSpecs = []
    },
    removeSpec(index) {
      this.specsData.styleTypes.splice(index, 1)
    },
    async queryStyleTypeList() {
      if (this.commonStyleTypeList.length > 0) return
      const res = await http({
        type: 'post',
        url: api.queryStyleTypeList,
      })
      this.commonStyleTypeList = res.result.styleTypeList
    },
    async saveSpecs() {
      this.modalLoading = true
      try {
        const res = await http({
          type: 'post',
          url: api.updateCategoryStyle,
          data: {
            categoryId: this.specsData.categoryId,
            styleTypes: this.specsData.styleTypes.map((item) => item.styleType),
          },
        })
        if (res.success) {
          this.$success(this.$t('保存成功'))
        }
        this.specsVisible = false
      } finally {
        this.modalLoading = false
        this.$emit('getList', String(this.specsData.indexLevel).split('-').slice(0, -1).join('-'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-tree {
  ::v-deep {
    .ant-table-row {
      cursor: pointer;

      &:hover td {
        background-color: #e6f7ff !important;
      }

      td:last-child {
        cursor: default;
      }
    }

    .ant-table-expanded-row {
      background: #fafafa;
    }

    .expand-icon {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.65);
      transition: all 0.3s;

      &:hover {
        color: #1890ff;
      }
    }
  }
}
</style>
