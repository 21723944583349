<template>
  <div class="category-manage">
    <div class="content">
      <a-button type="primary" @click="addCategoryModal({})">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增一级类目') }}
      </a-button>
      <div class="form-content">
        <a-row>
          <a-col :span="12">
            <a-select
              style="width: 100%"
              allowClear
              show-search
              v-model="formData.categoryId"
              :placeholder="$t('请输入类目名称')"
              :filter-option="false"
              :default-active-first-option="false"
              :showArrow="false"
              :not-found-content="null"
              @search="handleSearch"
            >
              <a-select-option v-for="d in searchData" :key="d.categoryId" :value="d.categoryId">
                <span v-html="highlightKeyword(d.categoryName, searchKeyword)"></span>
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="6" style="margin-left: 15px">
            <a-button type="primary" @click="init">
              <i class="icon iconfont icon-search" style="margin-right: 5px"></i>
              {{ $t('查询') }}
            </a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="category-list">
        <a-row>
          <a-col :span="24">
            <custom-tree
              ref="tree"
              :treeData="categoryTree"
              @loadData="loadData"
              @getList="reFreshList"
              @addCategory="addCategoryModal"
              @editCategory="editCategoryModal"
              v-if="show"
            />
          </a-col>
        </a-row>
      </div>
    </a-spin>
    <a-modal
      width="500px"
      v-model="visiable"
      :title="$t('新增类目')"
      :confirm-loading="modalLoading"
      @ok="createCategory"
    >
      <a-form-model>
        <a-form-model-item
          v-if="categoryModalInfo.categoryId"
          class="black"
          :label="$t('父级类目')"
        >
          <a-input disabled v-model="categoryModalInfo.categoryName"></a-input>
        </a-form-model-item>
        <a-form-model-item class="black" :label="$t('类目名称')" required>
          <a-input v-model="modalData.categoryName"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      width="500px"
      v-model="editVisiable"
      :title="$t('修改类目')"
      :confirm-loading="modalLoading"
      @ok="editCategory"
    >
      <a-form-model>
        <a-form-model-item class="black" :label="$t('类目名称')" required>
          <a-input v-model="categoryModalInfo.categoryName"></a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import http from '@service/axios'
import api from '@service/api'
import deepClone from '@/common/deepClone'
import { debounce } from 'lodash'
import CustomTree from '@/components/CustomTree'
export default {
  name: 'categoryManage',
  components: {
    CustomTree,
  },
  data() {
    return {
      formData: {
        categoryId: undefined,
      },
      visiable: false,
      editVisiable: false,
      modalData: {
        categoryName: undefined,
      },
      categoryModalInfo: {},
      oldTable: [], //存放老数据
      categoryTree: [],
      loading: true,
      modalLoading: false,
      show: false,
      searchData: [], // 搜索结果数据
      searchKeyword: '', // 添加搜索关键词存储
    }
  },
  created() {
    this.handleSearch = debounce(this.handleSearch, 500)
  },
  computed: {
    ...mapGetters(['platformMapping']),
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.show = false
      this.loading = true
      const list = await this.getDataList()
      this.categoryTree = list.map((item, index) => {
        item.indexLevel = index
        return item
      })
      this.loading = false
      this.show = true
    },
    // 获取列表页
    async getDataList(parentId = undefined) {
      let res = await http({
        type: 'post',
        url: api.queryCategoryList,
        data: {
          categoryId: this.formData.categoryId,
          parentId,
        },
      })
      let list = [...res.result.categoryList].map((item) => {
        if (item.leaf) {
          item.children = undefined
        }
        item.parentId = parentId
        return item
      })
      return list
    },
    async loadData(index) {
      // index: 0 1-0 1-2-1 有层级
      const indexes = index.toString().split('-').map(Number)
      let node = this.categoryTree[indexes[0]]
      for (let i = 1; i < indexes.length; i++) {
        node = node.children[indexes[i]]
      }
      if (node.leaf) return
      this.$set(node, 'loading', true)
      try {
        const list = await this.getDataList(node.categoryId)
        if (list.length > 0) {
          node.children = list.map((item, index) => {
            item.indexLevel = `${node.indexLevel}-${index}`
            return item
          })
          this.categoryTree = [...this.categoryTree]
        }
      } finally {
        this.$set(node, 'loading', false)
      }
    },
    addCategoryModal(data) {
      this.visiable = true
      this.categoryModalInfo = data
    },
    editCategoryModal(data) {
      this.editVisiable = true
      this.categoryModalInfo = deepClone(data)
    },
    //新建
    async createCategory() {
      if (!this.modalData.categoryName) {
        this.$warning(this.$t('请输入类目名称'))
        return
      }
      this.modalLoading = true
      try {
        await http({
          type: 'post',
          url: api.saveCategoryStyle,
          data: {
            parentId: this.categoryModalInfo.categoryId || undefined,
            categoryName: this.modalData.categoryName,
          },
        })
        this.$success(this.$t(`创建成功`))
        this.categoryModalInfo.leaf = false
        this.modalData = {
          categoryName: undefined,
        }
        this.visiable = false
      } finally {
        this.modalLoading = false
        this.reFreshList(this.categoryModalInfo.indexLevel)
      }
    },
    //编辑
    async editCategory() {
      if (!this.categoryModalInfo.categoryName) {
        this.$warning(this.$t('请输入类目名称'))
        return
      }
      this.modalLoading = true
      try {
        await http({
          type: 'post',
          url: api.updateCategory,
          data: {
            categoryId: this.categoryModalInfo.categoryId,
            categoryName: this.categoryModalInfo.categoryName,
          },
        })
        this.$success(this.$t(`修改成功`))
        this.editVisiable = false
      } finally {
        this.modalLoading = false
        this.reFreshList(
          String(this.categoryModalInfo.indexLevel).split('-').slice(0, -1).join('-')
        )
      }
    },
    reFreshList(index) {
      if (index === '') {
        this.init()
      } else {
        this.loadData(index)
      }
    },
    async handleSearch(value) {
      this.searchKeyword = value
      if (!value) {
        this.searchData = []
        return
      }
      try {
        const res = await http({
          type: 'post',
          url: api.searchCategory,
          data: {
            keyword: value,
          },
        })
        if (res.success) {
          const list = res.result.categoryList || []
          this.searchData = list.map((item) => ({
            categoryId: item.categoryId,
            categoryName: [...item.parentNames, item.name].join(' - '),
          }))
        }
      } catch (error) {
        console.error('搜索类目失败：', error)
      }
    },

    // 添加高亮显示方法
    highlightKeyword(text, keyword) {
      if (!keyword) return text
      const reg = new RegExp(keyword, 'gi')
      return text.replace(
        reg,
        (match) => `<span style="color: #f50; font-weight: bold">${match}</span>`
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.category-manage {
  .form-content {
    padding-top: 10px;
    .ant-form-item {
      width: auto !important;
    }
  }
}
.categoryModel ::v-deep .ant-form-item {
  display: flex;
  align-items: center;
  .ant-form-item-label {
    width: 40px;
    text-align: right;
    color: #666 !important;
  }
  .ant-form-item-control-wrapper {
    margin-bottom: 0px;
  }
}
.black {
  color: black;
}
.category-list {
  background-color: #fff;
  margin-top: 10px;
  min-height: 300px;
  padding: 0 20px; /* 添加内边距 */
}
::v-deep .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
